<template>
  <div
    class="airplay"
    style="color: white; display: block"
    @keydown="returnFn($event)"
  >
    <div
      class="back"
      tabindex="1"
      @click="back()"
      ref="back"
      @keydown="backKeyDown($event)"
    >
      Exit
    </div>
    <div class="text" v-show="$store.state.airPlayMac !== ''">
      macAdress: <span>{{ $store.state.airPlayMac }}</span>
    </div>
    <div class="text">
      status: <span>{{ $store.state.airplayState }}</span>
    </div>
    <div class="text" style="margin-top: 10px">
      <my-button
        ref="checkbox"
        @click="pair()"
        @keydown="radioKeyDown($event)"
        class="text"
        >airplay pairing</my-button
      >
    </div>
    <div class="text">How to connect?</div>
    <div class="step">
      <div class="step_row">
        <img tabindex="1" :src="step01" />
        <img tabindex="2" :src="step02" />
      </div>
      <div class="step_row">
        <img tabindex="3" :src="step03" />
        <img tabindex="4" :src="step04" />
      </div>
    </div>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
export default {
  mounted () {
    this.$refs.checkbox.focus()
  },
  data () {
    return {
      index: 0,
      imgUrl: require('@/assets/img/sm.png'),
      step01: require('@/assets/img/step1.png'),
      step02: require('@/assets/img/step2.png'),
      step03: require('@/assets/img/step3.png'),
      step04: require('@/assets/img/step4.png')
    }
  },
  created () {
  },
  methods: {
    back () {
      this.$router.replace('/')
    },
    radioKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        // case 'up':
        //   this.$refs.back.focus()
        //   break
        case 'ok':
          target.click()
          break
      }
    },
    backKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        case 'ok':
          target.click()
          break
      }
    },
    returnFn (e) {
      const key = keyMap[e.keyCode]
      if (key === 'return') {
        this.$router.replace('/')
      }
    }
  },
  beforeDestroy () {
    this.$plugin.send({
      data: [0x55, 0xaa, 0x02, 0xaa, 0x02, 0x16]
    })
  }
}
</script>
<style lang="scss" scoped>
.airplay {
  background-color: black;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 11;
  .back {
    display: block;
    width: 180px;
    padding-top: 20px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    &:focus {
      background: gray;
    }
  }
  img {
    height: 100%;
    &:focus {
      outline: blue solid 5px;
    }
  }
  .text {
    margin: auto;
    text-align: center;
    font-size: 30px;
  }
  button {
    min-width: 150px;
    font-size: 35px;
    padding: 5px;
    border-radius: 5px;
    &:focus {
      outline: yellow solid 3px;
    }
  }
  .step {
    height: 580px;
    margin: auto;
    overflow-y: auto;
    .step_row {
      width: 100%;
      height: 680px;
      margin: 20px 0;
      display: -webkit-flex;
      -webkit-justify-content: space-around;
      -webkit-align-items: center;
      img {
        display: block;
      }
    }
  }
}
</style>
